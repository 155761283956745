<template>
  <div class="container">

    <div v-if="$vuetify.breakpoint.smAndDown"
      class="body-2 d-flex justify-end">
      <v-card flat v-if="$store.state.user.userId" class="font-weight-bold mr-4 mb-3">
        Добро пожаловать, {{ $store.state.user.userName }}!
      </v-card>
    </div>

    <div class="body-1" v-if="!$store.getters.isAuthenticated">
      <p class="font-weight-bold px-4">Авторизируйтесь, чтобы получить полный доступ к приложению.</p>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p> -->
    </div>

    <div class="">
      <!-- СДЕЛАТЬ ОТДЕЛЬНЫМ КОМПОНЕНТОМ -->

      <v-card v-if="$store.getters.isAuthenticated" elevation="5"
              class="mb-6 mb-md-8" dark color="red">
        <div class="d-flex">
          <v-card-title class="white--text text-h5">
            <v-icon size="34px" color="white" left class="pr-4">
              mdi-trophy
            </v-icon>
            <span>Соревнования</span>
          </v-card-title>
        </div>

        <v-card-text class="my-n5 white--text text--darken-3">
          Общее описание соревнований в двух словах...
        </v-card-text>
        <v-card-actions>
          <v-btn class="white--text" text  @click="show = !show">
            Подробности
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-chip color="black white--text">BJJ</v-chip>
        </v-card-actions>

        <v-expand-transition>
          <div v-show="show">
            <v-divider></v-divider>
            <v-card-text>
              Детали о соревновнаиях - участники, адрес проведения и т.д.
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>

      <v-card elevation="5" class="mb-6 mb-md-8" color="transparent">
        <div class="d-flex">
          <v-card-title class="text-h5">
            <v-icon size="34px" color="red" left class="pr-4">
              mdi-information
            </v-icon>
            <span>Информация 1</span>
          </v-card-title>
        </div>

        <v-card-text class="my-n5 grey--text text--darken-3">
          Общее описание соревнования в двух словах...
        </v-card-text>
        <v-card-actions>
          <v-btn class="red--text" text  @click="show = !show">
            Подробности
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>

        </v-card-actions>

        <v-expand-transition>
          <div v-show="show">
            <v-divider></v-divider>
            <v-card-text>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>

      <v-card v-if="$store.getters.isAuthenticated" elevation="5" class="mb-6 mb-md-8" color="red" dark>
        <div class="d-flex">
          <v-card-title class="text-h5">
            <v-icon size="34px" color="white" left class="pr-4">
              mdi-trophy
            </v-icon>
            <span>Соревнования 2</span>
          </v-card-title>
        </div>

        <v-card-text class="my-n5 white--text">
          Общее описание соревнования в двух словах...
        </v-card-text>
        <v-card-actions>
          <v-btn class="white--text" text  @click="show = !show">
            Подробности
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-chip color="black white--text">MMA</v-chip>
        </v-card-actions>

        <v-expand-transition>
          <div v-show="show">
            <v-divider></v-divider>
            <v-card-text>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>

      <v-card elevation="5" class="mb-6 mb-md-8" color="transparent">
        <div class="d-flex">
          <v-card-title class="text-h5">
            <v-icon size="34px" color="red" left class="pr-4">
              mdi-information
            </v-icon>
            <span>Информация 2</span>
          </v-card-title>
        </div>

        <v-card-text class="my-n5 grey--text text--darken-3">
          Общее описание соревнования в двух словах...
        </v-card-text>
        <v-card-actions>
          <v-btn class="red--text" text  @click="show = !show">
            Подробности
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-card-actions>

        <v-expand-transition>
          <div v-show="show">
            <v-divider></v-divider>
            <v-card-text>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>

      <v-card elevation="5" class="mb-6 mb-md-8" color="transparent">
        <div class="d-flex">
          <v-card-title class="text-h5">
            <v-icon size="34px" color="red" left class="pr-4">
              mdi-information
            </v-icon>
            <span>Информация 3</span>
          </v-card-title>
        </div>

        <v-card-text class="my-n5 grey--text text--darken-3">
          Общее описание соревнования в двух словах...
        </v-card-text>
        <v-card-actions>
          <v-btn class="red--text" text  @click="show = !show">
            Подробности
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>
        </v-card-actions>

        <v-expand-transition>
          <div v-show="show">
            <v-divider></v-divider>
            <v-card-text>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </v-card-text>
          </div>
        </v-expand-transition>
      </v-card>

      <!-- СДЕЛАТЬ ОТДЕЛЬНЫМ КОМПОНЕНТОМ-->
      <div>
        <v-pagination color="red" v-model="page" :length="10" :total-visible="4"
          circle class="py-2">
        </v-pagination>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data:() => ({
    click: false,
    show: false,
    page: 1,
  })
}
</script>
